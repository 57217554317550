@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   
}

.side-main-container{
    display: flex;
    height: 90vh;
    overflow-y: auto;
}

.side-main-container .main-content{
    flex: 1;
}