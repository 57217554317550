.agreement-section{
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;
    padding: 20px 40px;
    width: 100%;
}

.sign-btn-cont {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  /* align-items: center; */
  /* flex-wrap: wrap; */
}

.resendoTp {
  border: none;
  border-bottom: 2px solid #1D1B21;
  padding-bottom: 1px;
  color: #1D1B21;
  font-weight: bolder;
  background-color: transparent;
  cursor: pointer;
  /* font-size: 16px; */
}

.disable-resend {
  color: #838F97;
  border-bottom: 2px solid #838F97;
  cursor: not-allowed;
}

.agree-cont{
  position: relative;
  /* border: 1px solid grey;
  width: 70%;
  height: 70%; */
  display: none;
  /* margin: 0px auto; */
}

.agree-img{
  /* width: 100px;
  height: 100px; */
  /* object-fit: cover; */
  border: 1px solid lightgray;
  width: 100%;
  /* height: 100%; */
  margin: 0px auto;
  border-radius: 15px;
}

.icons-cont{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index:11;
}

.agree-icons{
  background-color: white;
  padding:3px 10px;
  border-radius: 8px;
  /* width: 100px; */
}

.process-succ-mes{
    margin-top: 10px;
    font-size: 20px;
}



/* for mobile */
@media (max-width: 767px){
  .agreement-section{
    margin-top: 10px;
  }
  .sign-btn-cont{
    flex-direction: column;
    gap: 10px;
    align-items: start;
    padding: 0px 6px;
  }
  iframe{
    width: 300px;
    height: 500px;
    display: none;
}
.agree-cont{
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}
.message-only-conatiner {
  height: 40vh;
  text-align: center;
}
}


@media (min-width:768px) {
   .dividescreen .agreement-section{
      flex-direction: row;
      gap: 30px;
      /* padding: 20px 150px; */
    }
    .dividescreen .pd-key-value{
      width: 100% !important;
    }
   .dividescreen iframe{
      height: 100%;
      flex: 2;
    }
    .dividescreen .sign-btn-cont{
      flex: 1;
    }

}