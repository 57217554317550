.api-loader-container{
    width: 70px;
    height: 70px;
    position: absolute;
    top: 50%;
     left: 50%;
  /* -ms-transform: translate(-50%, -50%); */
  transform: translate(-50%, -50%);
}

.api-loader-container > img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}