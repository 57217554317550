.verify-mobile-comp{
    height: 90vh;
    width: 70%;
    margin: 0px auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.mb-consent-check-cont{
        display: flex;
        gap: 8px;
        align-items: flex-start;
    }

    .vm-heading-text{
        font-size: 24px;
        margin-top: 15px;
    }

    @media (max-width: 767px){
        .verify-mobile-comp{
            width: 95%;
        }
      }
