.navbar{
    height: 10vh;
    /* background-color: ; */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
    padding: 10px 30px;
    border-bottom:1px solid #0f172a1a;
    display: flex;
    align-items: center;
    min-height: 62px;
}

.wizr-logo{
    height: 40px;
}
