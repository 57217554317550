.payment-details-container{
    width: 100%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding-top: 50px;
}

.payment-details-container > div {
    width: 50%;
}

.pd-header{
    margin-bottom: 20px;
}

.payment-detail{
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    gap: 20px;
    flex-wrap: wrap;
}

.pd-key-value{
    display: flex;
    flex-direction: column;
    width: 40%;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    height: max-content;
    

}

.pd-key {
    font-size: 18px;
    font-weight: 400;
    color: #959595;
    
}

.pd-value{
    margin-top: 0.3rem;
    font-size: 20px;
    color: #000;
    font-weight: 700;
}

.pd-payment-btn{
    margin-top: 20px;
}


/* for mobile */
@media (max-width: 767px){
    .payment-details-container{
        justify-content: start;
        margin-top: 20px;
    }
    .payment-details-container > div {
        width: 80%;
    }
    .pd-key-value{
        width: 80%;
    }
}

