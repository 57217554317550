.sidebar{
    width: 250px;
    min-width: 250px;
    border-right: 1px solid lightgray;
   height: 100%;
   overflow-y: auto;
   scroll-behavior: smooth;
}

.stepWrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    }
    
    .sidebar::-webkit-scrollbar {
      width: 5px;
      /* height: 10px; */
      /* display: none; */
    }
    
    .sidebar::-webkit-scrollbar-track {
      background-color: #eceef2;
      border-radius: 180px;
    }
    
    .sidebar::-webkit-scrollbar-thumb {
      background-color: #9b9b9b;
      border-radius: 180px;
    }

.stepBlock{
    min-height: 68px;
    display: flex;
    gap: 10px;
   }
  
  .stepBlock .circleWrapper {
    position: relative;
  }

  .stepBlock .circleWrapper img {
    position: relative;
    z-index: 1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: contain;
    margin: -2px;
    display: block;
}
  
  .selected .circle, .selected img {
    color: black;
  }
  
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 0px;
    height: 68px;
    position: absolute;
    top: 0px;
    left: -1px;
    right: 0px;
    margin: auto;
    border-right: 1.5px dashed #959595;
}
  
  .selected.stepBlock:not(:last-child) > .circleWrapper::after {
    border-right: 1px solid rgba(154, 212, 80, 1);
  }

  
  .stepBlock:not(:last-child).selected > .circleWrapper::after, .stepBlock:not(:last-child).selected ~ .stepBlock:not(:last-child) > .circleWrapper::after {
    opacity: 0.6;
  }
  
  .stepBlock.activestage{
     color: blue;
  }
  
  .stepBlock.activestage .circle{
    border: 1px solid blue;
  }

  .circle {
    width: 20px;
    height: 20px;
    line-height: 25px;
    background-color: #FFFFFF;
    color: white;
    border-radius: 50%;
    border: 1px solid #999999;
    cursor: pointer;
    -moz-background-clip: padding-box;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    margin-left: -2px;
    text-align: center;
    position: relative;
    z-index: 1;  
  }
  

  @media screen and (max-width: 768px) {
    .sidebar{
    display: none;
    }
  }
  

