.kyc-success-cont{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.kyc-success-cont > img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.kyc-success-cont > p{
    font-size: 24px;
    font-weight: 800;
    text-align: center;
    padding: 0px 10px;
}

.refresh-loader-cont{
        display: flex;
        gap: 10px;
        align-items: center;
    }
    
          .refresh-loader {
            width: 48px;
            height: 48px;
            border:10px solid #FFF;
            border-radius: 50%;
            position: relative;
            transform:rotate(45deg);
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .refresh-loader::before {
            content: "";
            position: absolute;
            box-sizing: border-box;
            inset:-10px;
            border-radius: 50%;
            border:10px solid blue;
            animation: prixClipFix 30s infinite linear;
          }

          .refresh-loader  span{
             display: inline-block;
             transform:rotate(-45deg);
             
          }
      
          @keyframes prixClipFix {
              0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
              25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
              50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
              75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
              100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
          }
            