.pnach-container{
        width: 100%;
        height: 100%;
        overflow-y: auto;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        flex-direction: column;
        gap: 20px;
       padding: 25px 30px;

}

.pnach-content-box{
    display: flex;
    flex-direction: row-reverse;
    gap: 40px;
}

.pnach-btns{
    display: flex;
    gap: 10px;
}

.pnach-instructions {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    gap: 10px;
    border: 1px solid lightgray;
    /* padding: 10px; */
    min-height: max-content;
    height: 300px;
    overflow-y: auto;
}

.pnach-instructions > ul{
    padding-left: 16px;
    margin-left: 10px;
}
.pnach-instructions > ul > li{
    font-weight: 500;
    padding: 3px 0px;
    font-size: 13px;
}

.pnach-instructions > ul > li > ul{
        /* font-size:10px; */
 padding-left: 30px;
}
.pnach-instructions > ul > li > ul > li{
     font-weight: 400;
     padding: 3px 0px;
     font-size: 13px;
}

.pnach-htext{
        background-color: lightgray;
        padding: 10px;
        /* margin-top: 4px; */
        border-radius: 2px;
        
}

.text-red{
    color: red;
}

@media screen and (max-width: 768px) {
    .pnach-content-box{
        flex-direction: column;
    }
  }